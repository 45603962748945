.side{
    background-color:#118055;
  //  min-height:100%;
   // position:fixed;
  //  width:300px;
  .side-content{
     bottom:10px;
     overflow:auto;
    width:100%;
  }
    nav{
        width:100%;
        ul{
            list-style: none;
        }
    }
    li ul{
        padding-left:.5em;
    }
    nav > ul > li{

        margin-bottom:1em;
    }
    .admin-tools{
      display:none;
    }
    .adm:hover{
        .admin-tools{
            padding-left:.5em;
            display:inline-block;
            img{
                width:15px;
                height:auto;
                color:white;
            }

          }

    }
    li >ul{
        margin-top:1em;
    }
    li a{
        color:white;
        font-size:1em;
        font-weight:bold;
        text-decoration: none;
    }

    span.open-close{
        color:white;
        font-size:1.5em;
        font-weight:bold;
       width:15px;
       display: inline-block;
        cursor:pointer;
    }

    .btn{
        display:flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        a{
           color:white;
           padding:.5em 2em;
           text-decoration: none;
           margin-bottom:1em;
           font-size:.7em;
            border:1px solid white;
        }
    }
}