.side-editor{

    position:fixed;
    right:0;
    width:300px;
    bottom:0;
    top:60px;
    z-index:5;
    background-color:white;
overflow: auto;
border-left:1px solid gray;
.errors{

    padding:1em;
    p{
        color:red;
        font-weight: bold;
        margin-bottom:0;
    }
}
    .inner{

        padding:1em;

        h2{
            font-size:1em;
            span{
                img{
                    width:20px;
                    height:auto;
                    cursor:pointer;
                }
            }
        }
        .button-holder{
            text-align: center;
            margin-top:1em;
            &.close{
                button{
                background-color:white;
                color:black;
                text-decoration: underline;
                padding:0;
                }

            }
            &.remove{
 margin-top:3em;
                button{
                    background-color:red;
                }
            }
        button{
cursor: pointer;
            background-color:black;
            color:white;
            border:0;
            outline:0;
            border-radius:10px;
            padding:1em;
            font-size:.8em;
            width:100%;

            &:disabled{
                opacity:.5;
            }
        }
    }

    select{
        display: block;
        width:100%;
        margin-top:1em;
    }
    h5{
        font-size:.7em;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom:0;
    }
    label{
        display: block;
        margin-bottom:1em;
        
            font-size:.7em;
            font-weight: bold;
            text-transform: uppercase;
            
      
       
        input{
            width:100%;
        display: block;
        }

        textarea{
            width:100%;
        display: block;
        }
    }
    }
}

.selected-folders{
    display: flex;
    margin-top:1em;
    flex-direction: row;
    flex-flow: wrap;
    span{
        border:1px solid gray;
        padding:.3em;
        border-radius:8px;
        margin:.5em;
        font-size:.8em;
        font-weight: bold;
        cursor:pointer;
        &:after{
            content:"x";
            padding:.5em;
            color:red;
        }
    }
}
.filesadd h1{

    font-size:1.2em;
    color:#118055;
}
.filesadd .errors{

    color:red;
    font-weight: bold;
}
.file-col{
    display:flex;
    width:100%;
    border-bottom:1px solid lightgray;
    padding-bottom:1em;
    padding-top:1em;
    .fields,h3{
        padding-right:3em;
    }
    h3{
        margin-top:0;
        margin-bottom:0;
    }
    h5{
        font-size:.7em;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom:0;
    }
    select{
        display: block;
        width:100%;
        margin-top:1em;
    }
    label{
        display: block;
        margin-bottom:1em;
        
            font-size:.7em;
            font-weight: bold;
            text-transform: uppercase;
            
      
       
        input{
            width:100%;
        display: block;
        }
        
        textarea{
            width:100%;
             display: block;
        }
    }
}