.loginContainer{


    max-width:270px;
    width:90%;
    margin:0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    input{
        display:block;
       
        width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  box-sizing: border-box;
  margin-top:8px!important;
    }

    input[type=submit]{
text-transform: uppercase;
    }
    label{
        margin-bottom:.5em;
        display:block;
    }
    span.unohtuiko{
        text-decoration: underline;
        cursor: pointer;
    }

    p.infoa{
        font-size:0.8em;
    }
}