.folder-browswer{
.content{
display: flex;
flex-flow: row wrap;

.folder-header{
    flex-basis:100%;
    ul{
        list-style: none;
        padding-left:1em;

        li{
            display: inline;
            &:after{
                content:" > ";
            }
            
        }
        a{
            color:black;
            font-weight: bold;
            font-size:.8em;
            text-decoration: none;
        }
        li:last-child{

            &:after{
                content:"";
            }

        }

    }
}
    .item{
        &.active{
            background:black;
            color:white;
        }
        flex: 0 0 20%;
        overflow: hidden;
        margin-bottom:1em;
        .inner{
            padding:1em;
        }
        a{
            color:black;
            text-decoration: none;
        }
        .text{

            h3{
                font-size:.8em;
                font-weight:normal;

                display: inline-block;
  
    

            }

        }
        .image-holder{
           
            position:relative;
            padding-bottom:135%;
            .image{


                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;

                img{
            
           
                    width:100%;
                    height:auto;
        
                 }


            }
          


        }
       
    }

}
}

@media all and (max-width: 1000px) {


body{
  
    .folder-browswer{
        .content{

            .item{
                flex: 0 0 33.33%;
            }
        }

    }
}
}

@media all and (max-width: 700px) {


    body{
      
        .folder-browswer{
            .content{
    
                .item{
                    flex: 0 0 50%;
                }
            }
    
        }
    }
    }