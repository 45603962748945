header{
   li{
    a.active{
        font-weight: bold;
    }

   }
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
    .items-holder{
        position:relative;
        width:100%;
        .items{
            position:absolute;
            background-color:#118055;
            z-index:2;
            right:0;
            left:0;
            width:100%;
            >div{
                color:white;
                margin:1em;
                
            }
            a{
                text-align: center;
                height: auto;
                color:white;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    ul{
       list-style: none;
       display: flex;
       align-items: center;
      margin:0;
      li.logout{
          font-size:.9em;
          cursor:pointer;
          &:hover{
              color:red;
          }
      }
        li{
          
          
           background-color: white;
           margin-right:1em; 
            a{
              color:black;
              text-decoration: none;
              height:100%;
              display: block;
              
            }
        }
        li.search-item{
            align-self: stretch;  
            margin-left: auto;
          position:relative;
            height:60px;
            .search-holder{
                height:60px;
                display: flex;
                align-items: center;
            }
            ul{
                display: block;
                padding-left:0;
                background-color:#118055;
                position:absolute;
               
                li{
                    
                    margin-right:0;
                    min-width:300px;
                    color:white;
                    background-color:#118055;

                    &.no-result{
                        padding-top:1em;
                            padding-bottom:1em;
                            font-style:italic;
                        text-align: center;
                    }
                    &.close{
                        padding-top:.75em;
                        cursor:pointer;
                        padding-bottom:.75em;
                        font-size:.8em;
                        text-decoration: underline;
                    }
                    &.to-search{

                        text-align: center;
                        a{
                            border:2px solid white;
                            padding-top:1em;
                            padding-bottom:1em;
                            margin-left:.2em;
                            margin-right:.2em;
                            margin-bottom:2px;
                            margin-top:.2em;
                            color:white;
                        }
                    }
                   
                }

            }
        }
        li.basket-li{
           
            height:60px;
            background-color:#118055;
            line-height:60px;
            margin-right:0;
            .basket{
                margin:0 2em;
                color:white;
                span{
                    margin-right:.6em;
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                    line-height:30px;
                    width:30px;
                    height:30px;
                    border: 1px solid white;
                }

               
            }
        }
        li.logo{

            margin-right:2em; 
           width:120px;
            img{
                width:100%;
                max-width:120px;
                height:auto;
            }
        }
    }
}
.smallfile{
    cursor: pointer;
    margin:.2em;
    padding:.2em;
    font-size:.7em;
    background-color: white;
    color:black!important;
    line-height: 1em;
    display:flex;
    width:100%;
    .image{
        padding-right:.4em;
        flex-basis:40px;
        max-width:40px;
        img{
            width:100%;
            height:auto;
        }
    }

}