@import "./scss/header";
@import "./scss/login";
@import "./scss/sidenav";
@import "./scss/folderbrowser";
@import "./scss/side-editor";

body,html{
  height:100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size:16px;
}

.users.faint .items{
  opacity:.3;
}
p.user_role{
  font-size:.8em;
}
#root,body{

  min-height:100%;
}
.loginForm{
  label{
    text-transform: uppercase;
    input{
      margin-top:.2em;
    }
  }
}
.App{
  min-height: 100vh;
}
header{
  position:fixed;
  width:100%;
  background-color:white;
  z-index:12225;
}
.main-content{

  display:flex;
  flex-direction: row;
 
  height: 100%;
  min-height: 100vh;
  align-items: stretch;
 .folder-browswer .content{
   margin-top:70px;
 }
 .close-me{
  text-align: center;
  margin-top:.5em;
  cursor: pointer;
 }
 .users-holder{
  width:100%;
   .content{
      margin-top:70px;
   }

   .item.notactive{
    opacity:.3;
   }
 }
  .side{
    .content{
     
    }
    flex: 0 0 300px;
   
    .side-content{
      position: fixed;
      top:70px;
      width:300px;
    }

  }

  .folder-browswer{

    flex:1;

    &.filesadd{
      .content{
        display: block !important;
        padding-left:1em;
        padding-right:1em;
        width:100%;
        .row{
          
        }
      }

      .button-holder{
        text-align: center;
        margin-top:1em;
       
    button{
cursor: pointer;
        background-color:black;
        color:white;
        border:0;
        outline:0;
        border-radius:10px;
        padding:1em;
        font-size:.8em;
        width:100%;

        
    } }









    }
  }
  
}

.items{

  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item{
    cursor:pointer;
    text-align: left;
    flex: 0 0 25%;
    border:1px solid black;
    margin:0.5em 1em ;
    .inner{
      padding:1em;
    }
  }
}
.search-results .content{
  padding-top:100px;
}